import { ENV, IS_MOCK } from './constants'

const ENV_PATH = IS_MOCK ? 'DEMO' : ENV

export const MOCK_ID = 'example'

export const AVATAR_MOCK = `${ENV_PATH}/user/avatar.jpeg`

export const PORTFOLIO_MOCK = [
  `${ENV_PATH}/user/portfolio_1.jpeg`,
  `${ENV_PATH}/user/portfolio_2.jpeg`,
  `${ENV_PATH}/user/portfolio_3.jpeg`,
  `${ENV_PATH}/user/portfolio_4.jpeg`,
]

export const IMAGES_MOCK = [
  `${ENV_PATH}/user/session_0.jpeg?height=3682&left=2760&rotate=0&top=7&width=2762&x=-200.99927542414517&y=11.805141035467301&zoom=1.043785042688251`,
  `${ENV_PATH}/user/session_1.jpeg?height=2299&left=1392&rotate=0&top=158&width=1724&x=-65.30592418968699&y=6.491323556899843&zoom=1.159842728376391`,
  `${ENV_PATH}/user/session_2.jpeg?height=2667&left=505&rotate=0&top=-4&width=2000&x=114&y=1&zoom=1`,
  `${ENV_PATH}/user/session_3.jpeg?height=2517&left=1794&rotate=0&top=7&width=1888&x=-169.0159335613674&y=15.633390139709007&zoom=1.0596351901441832`,
  `${ENV_PATH}/user/session_4.jpeg?height=3239&left=361&rotate=0&top=342&width=2429&x=215.8600035239012&y=-28.760815428234658&zoom=1.1174815075844535`,
]
