export * from '@style-space/components/src/lib/utils/mock'
import { MOCK_ID } from '@style-space/components/src/lib/utils/mock'

import {
  BookingFeedback,
  FeaturedStylist,
  FullSession,
  FullSessionBooking,
  HappySession,
  Review as ReviewData,
  SessionBooking,
  SessionWithMain,
  Stylist,
} from '../../@types/types'
import { ENV } from './constants'
import Testimonial1 from '../../public/images/testimonial1.png'
import Testimonial2 from '../../public/images/testimonial2.png'
import Testimonial3 from '../../public/images/testimonial3.png'

export const getMockImages = (
  amount: number,
  width: number,
  height: number,
) => {
  return new Array(amount)
    .fill(null)
    .map((_, i) => `https://picsum.photos/id/${i}/${width}/${height}`)
}

export const getHappySessionMock = (amount: number): HappySession[] =>
  new Array(amount).fill(null).map((_, index) => ({
    testimonial:
      'I am an award-winning celebrity stylist for clients, most notably.'.repeat(
        50,
      ),
    category: 'HAIR',
    image: Testimonial1,
    headline: `Title-${index}`,
    id: `daf-${index}`,
    slug: `daf-${index}`,
    fullName: `Name - ${index}`,
  }))

export const getFeaturedStylistsMock = (amount: number): FeaturedStylist[] =>
  new Array(amount).fill(null).map((_, index) => ({
    id: `daf-${index}`,
    slug: `daf-${index}`,
    fullName: `Name - ${index}`,
    avatar: 'https://picsum.photos/276/333',
    category: 'HAIR',
    about:
      'I am an award-winning celebrity stylist for clients, most notably. '.repeat(
        1,
      ),
  }))

export const MOCK_IMAGE = 'https://picsum.photos/200'
export const MOCK_REVIEW =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id mollis mauris. Sed vel tellus magna. In hac habitasse platea dictumst. Morbi feugiat ligula vel vulputate condimentum. Nulla sed hendrerit nisi, sed convallis nulla. Pellentesque vitae tortor condimentum, congue ligula et, efficitur dolor. Nunc vulputate gravida mi, at tincidunt mi finibus vel. Donec eu elit consequat, egestas metus id, pretium augue. Phasellus metus magna, tristique at tristique vel, condimentum et tellus. At tincidunt mi finibus vel. Donec eu elit consequat, egestas metus id, pretium augue. Phasellus metus magna, tristique at tristique vel, condimentum et tellus.'

export const getMockSessionData = (amount: number): SessionWithMain[] => {
  return new Array(amount).fill(null).map((_, index) => ({
    id: `daf-${index}`,
    slug: `daf-${index}`,
    images: [],
    rating: { numberOfRatings: 400, averageScore: 5.3 },
    price: { amount: 50, currency: 'USD' },
    duration: { minutes: 10 },
    mainImage: MOCK_IMAGE,
    category: 'MAKE_UP_SKIN_CARE',
    name: `Title-${index}`,
    description:
      'Maecenas porta condimentum neque, a lobortis erat. Nunc aliquam posuere finibus.',
  }))
}

export const getMockSessionBookingData = (amount: number): SessionBooking[] => {
  return new Array(amount).fill(null).map((_, index) => ({
    id: `daf-${index}`,
    stylistSlug: `daf-${index}`,
    mainImage: MOCK_IMAGE,
    stylistID: 'stylistID',
    name: `Title-${index}`,
    clientFullName: 'Full Name',
    bookingStatus: 'CONFIRMED',
    sessionId: '123',
    hasFeedback: false,
    stylistFullName: 'Full name',
    canAddFeedback: false,
    category: 'HAIR',
    amount: 500,
    currency: 'USD',
    when: '2020-04-20T20:20',
    duration: 60,
  }))
}

export const getMockReviewData = (amount: number): ReviewData[] => {
  return new Array(amount).fill(null).map((_, index) => ({
    comments: MOCK_REVIEW,
    score: 5,
    reviewer: {
      avatar: MOCK_IMAGE,
      fullName: `User Name ${index}`,
    },
    date: '2021-01-27T08:04:22.141013',
  }))
}

export const STYLIST_MOCK: Stylist = {
  id: MOCK_ID,
  slug: MOCK_ID,
  isActive: true,
  isFeatured: false,
  category: 'CLOTHING',
  fullName: 'Adriana Caron',
  about:
    'I am a Parisian born and raised fashionista! After my education at Parsons Paris, I worked at some of the leading fashion houses like Gucci, Prada and Chanel styling fashion shoots. I learned some powerful lessons in the fashion industry that I want everyone to know about and apply in their everyday dress. \n' +
    '\n' +
    'I will guide all of my clients to their best look; one that fits their personality, lifestyle and helps them feel their very best, every day! \n',
  philosophy:
    '"What you wear is how you present yourself to the world, especially today, when human contacts are so quick. Fashion is instant language." —Miuccia Prada\n' +
    '\n' +
    'Everyone can feel powerful through the way they dress. ',
  awards: [
    'Winner of 2017 H&M Design Award',
    'Winner of 2018 H&M Design Award',
  ],
  experience: [
    'Parson Paris BA',
    '5 y + Styling at Prada, Gucci, Chanel',
    '10 years+ Personal Styling',
  ],
  languages: ['English', 'French'],
  strongSuits: [
    'Online Shopping',
    'Outfit Pairing',
    'Dressing Best for all Body Types',
    'Creating unique styles',
    'Color Matching',
  ],
  city: 'Los Angeles',
  country: 'United States',
  likes: [
    'Classic Silhouettes',
    'Natural base colors and a pop of color',
    'Timeless fashion pieces',
  ],
  portfolio: [
    `${ENV}/user/portfolio_1.jpeg`,
    `${ENV}/user/portfolio_2.jpeg`,
    `${ENV}/user/portfolio_3.jpeg`,
    `${ENV}/user/portfolio_4.jpeg`,
  ],
  avatar: `${ENV}/user/avatar.jpeg`,
  rating: {
    averageScore: 4.78,
    numberOfRatings: 46,
  },
  experienceTime: 5,
}

const IMAGES_MOCK = [
  `${ENV}/user/session_0.jpeg?height=3682&left=2760&rotate=0&top=7&width=2762&x=-200.99927542414517&y=11.805141035467301&zoom=1.043785042688251`,
  `${ENV}/user/session_1.jpeg?height=2299&left=1392&rotate=0&top=158&width=1724&x=-65.30592418968699&y=6.491323556899843&zoom=1.159842728376391`,
  `${ENV}/user/session_2.jpeg?height=2667&left=505&rotate=0&top=-4&width=2000&x=114&y=1&zoom=1`,
  `${ENV}/user/session_3.jpeg?height=2517&left=1794&rotate=0&top=7&width=1888&x=-169.0159335613674&y=15.633390139709007&zoom=1.0596351901441832`,
  `${ENV}/user/session_4.jpeg?height=3239&left=361&rotate=0&top=342&width=2429&x=215.8600035239012&y=-28.760815428234658&zoom=1.1174815075844535`,
]

export const HAPPY_MOCK: HappySession[] = [
  {
    image: Testimonial1 as any,
    headline: 'I upgraded my wardrobe after I had a baby',
    testimonial:
      'After becoming a mom for a second time, I found I could not wear most of my previous wardrobe, due to my mommy body changing. Jenna helped me work with what I already have, and then guided me to staples I could purchase that would complete a functional capsule wardrobe. I feel so confident after my wardrobe session with Jenna. I have received several compliments already on my fresh style and still feel comfortable in what I’m wearing! It doesn’t get any better than that!',
    id: '5560453a-93a2-aa3f-42e9-9d0a8e724e2b',
    slug: 'mamas-capsule-wardrobe',
  },
  {
    image: Testimonial2,
    headline: 'Revolutionized and simplified daily routine',
    testimonial:
      "Taking a hair and makeup session from Style Space revolutionized and simplified my daily routine. I've been armed with products and techniques that fit my exact style and are long-lasting enough to take me from mom in the morning to book signings in the afternoon and date night in the evening. The clothing session was the icing on an already fantastic cake. I needed to find a few key pieces to build a more professional look, and my stylist completely came through. Thank you for helping me rejuvenate my look!!",
    id: 'gqztcmrrga4teoa',
    slug: 'cut-color-and-style-consultation',
  },
  {
    image: Testimonial3,
    headline: 'I got styled for a fancy event',
    testimonial:
      'My style session with Katie was perfect! I loved every minute of it! She totally understood me, my style and gave me some amazing style recommendations. I selected a gold dress that I wore to a very important business event in NYC. It was a show stopper! Overall, amazing experience and a great way to prepare for any event. I highly recommend Style Space for anyone looking to level up their style',
    id: 'e530f5e1-7c06-5a3c-0eaf-b0ee30ba1fe9',
    slug: 'personal-shopping',
  },
]

export const SESSION_MOCK: FullSession = {
  id: MOCK_ID,
  slug: MOCK_ID,
  isFavorite: false,
  isFeatured: false,
  tags: [],
  gender: 'MALE',
  category: 'CLOTHING',
  name: 'Online Shopping Session',
  description:
    'In our scheduled video call, we will discuss your survey details and talk about the best silhouettes to flatter your body. Next, we will talk about patterns and colors that will make you stand out. Lastly we will talk about the best designs to fit your lifestyle. \n' +
    'With all of this information, I will take you on a virtual shopping tour with preselected brands I have chosen for you. As we look at different pieces, you can tell me what you like and don’t like. At the end of the call, you will have a virtual shopping cart filled with clothing pieces you will love and will complete your wardrobe. You can choose to purchase whatever items you like at your leisure.',
  price: {
    amount: 100,
    currency: 'USD',
  },
  stylist: {
    slug: MOCK_ID,
    id: MOCK_ID,
    fullName: 'Adriana Caron',
    location: { city: 'Los Angeles', country: 'USA' },
    avatar: `${ENV}/user/avatar.jpeg`,
    category: 'HAIR',
    about:
      'I am a Parisian born and raised fashionista! After my education at Parsons Paris, I worked at some of the leading fashion houses like Gucci, Prada and Chanel styling fashion shoots. I learned some powerful lessons in the fashion industry that I want everyone to know about and apply in their everyday dress. \n' +
      '\n' +
      'I will guide all of my clients to their best look; one that fits their personality, lifestyle and helps them feel their very best, every day! \n',
  },
  images: IMAGES_MOCK,
  duration: {
    minutes: 60,
    hours: 1,
  },
  rating: {
    averageScore: 4.78,
    numberOfRatings: 46,
  },
  whatToBring: [
    'Black Mascara',
    'Your own kind of foundation',
    'Something else',
    'And the last item they need to bring',
  ],
}

export const MOCK_NOTES: BookingFeedback = {
  personalNote:
    'I am including inspirational pictures of the cuts and curtain bangs best suiting your facial structure, hair type, and manageability. I am also including helpful volumizing tools and products to help you achieve the final look. Together we will create a blueprint to confidently offer your stylist. \n\n  I would also suggest a post salon styling session with me. I would love to help you properly style and use the products I suggested.',
  notes:
    'With your busy lifestyle it is important to have everyday low maintenance hair, but I also want you to have the versatility to jazz it up for special occasions.  \n\n The TIGI Recharge shampoo will be a good alternative for you. I always like using more than one. Alternate on the days you shampoo. Light on the conditioner, use when needed and sparingly on ends. NatureLab Leave-in is a great foam based conditioner that will not weigh you down, as an alternative to using conditioner.  Kenra Dry Burst is a fantastic volume styling aid. You use it like a dry shampoo, but more sparingly. You can spray this in the areas you seek volume and rub the scalp until it dries, or with the help of a blow-dryer.  Ogx is an amazing dry shampoo for those second and third days. It produces a nice amount of volume too. This is a more affordable option to the Kenra.\n\n I would continue to practice curling your hair. I love the loose curls on you. The roller technique is also super easy addition.',
  takeaways: [
    'Long layered lob haircut with curtain bangs is your ideal cut.',
    'List of products and tools to help achieve the look and add more volume.',
    'Alternate shampoos to keep your hair more voluminous.',
    'Practice with the inch barrel iron to create those beautiful wavy curls.',
    'Would love a follow up style session to reinforce all techniques and products.',
  ],
  moodboards: [
    {
      name: 'Cuts',
      description:
        'These cuts are perfect for your hair texture will be very low maintenance. The layers are long, but short enough to give you that volume you need. I suggest never to let anyone razor cut your hair. It will make it fray and look like cotton candy. These long layered "Lobs," are really just wash and go with some work to the bangs. The curling technique we practiced will be the curled version of these haircuts. I think the lengths of the first 3 are perfect.',
      images: IMAGES_MOCK,
    },
    {
      description:
        'These are the curtain bangs I suggest. All will work well with your slight offset part and beautiful hair. It is up to you and your preference. You can always stay with the longer version until your are ready to work up to the shorter thicker versions. It is really up to your comfort level.  They are all winners here!',
      images: [
        IMAGES_MOCK[1],
        ...IMAGES_MOCK.slice(0, 1),
        ...IMAGES_MOCK.slice(2, IMAGES_MOCK.length),
      ],
      name: 'Curtain bangs',
    },
    {
      description:
        'I suggest Velcro rollers  (use clips or bobby pins and base to anchor) for volume. The pictures will show you how to place the rollers. This technique is perfect after a fresh blowdry, or even 2nd day hair. Place the rollers as shown, mist with hairspray. Hit the curls with a hot blow-dryer for 3 mins, direct heat to each one for at least 20 seconds. Keep the dryer moving so you do not burn your scalp.  Leave in at least 20 mins  You can simply brush and then spray in place.',
      images: [
        IMAGES_MOCK[2],
        ...IMAGES_MOCK.slice(0, 2),
        ...IMAGES_MOCK.slice(3, IMAGES_MOCK.length),
      ],
      name: 'Volume!!',
    },
  ],
  recommendedProducts: [
    {
      name: 'TIGI Recharge shampoo and conditioner',
      url: 'https://www.amazon.com/Tigi-Recharge-Shampoo-Conditioner-25-36oz/dp/B00D3SJHIC',
    },
    {
      name: 'Kenra Volume Burst',
      url: 'https://www.amazon.com/Kenra-Burst-Instant-Volumizing-Spray/dp/B079NN912V',
    },
    {
      name: 'NatureLab Perfect Leave-In Conditioner',
      url: 'https://www.amazon.com/NatureLab-Perfect-Repair-Leave-Treatment/dp/B085B8P66Y/ref=mp_s_a_1_1?crid=GIK2W6RDR38G&dchild=1&keywords=tokyo+lab+leave+in+conditioner&qid=1633797354&sprefix=tokyo+lab+leave+&sr=8-1',
    },
    {
      name: 'OGX Dry Shampoo, Coconut Miracle Oil',
      url: 'https://www.amazon.com/OGX-Strength-Refresh-Restore-Coconut/dp/B076RXVCWV/ref=mp_s_a_1_3?dchild=1&keywords=ogx+coconut+dry+shampoo&qid=1633797320&sr=8-3',
    },
    {
      name: 'Jumbo Velcro Rollers',
      url: 'https://www.amazon.com/velcro-hair-rollers/s?k=velcro+hair+rollers',
    },
  ],
}

export const MOCK_SESSION_TAGS: string[] = [
  'Dress for Success',
  'Elevate your style',
  'Dress your body type',
  'Color analysis',
  'Travel',
]

export const MOCK_SESSIONS_DATA: SessionWithMain[] = [
  {
    id: MOCK_ID,
    slug: MOCK_ID,
    category: 'CLOTHING',
    name: 'Online Shopping Session',
    images: [],
    description:
      'What you will do\n' +
      'In our scheduled video call, we will discuss your survey details and talk about the best silhouettes to flatter your body. Next, we will talk about patterns and colors that will make you stand out. Lastly we will talk about the best designs to fit your lifestyle. \n' +
      'With all of this information, I will take you on a virtual shopping tour with preselected brands I have chosen for you. As we look at different pieces, you can tell me what you like and don’t like. At the end of the call, you will have a virtual shopping cart filled with clothing pieces you will love and will complete your wardrobe. You can choose to purchase whatever items you like at your leisure.',
    price: {
      amount: 100,
      currency: 'USD',
    },
    mainImage: `${ENV}/user/session_0.jpeg?height=3682&left=2760&rotate=0&top=7&width=2762&x=-200.99927542414517&y=11.805141035467301&zoom=1.043785042688251`,
    duration: {
      minutes: 60,
    },
    rating: {
      averageScore: 4.78,
      numberOfRatings: 46,
    },
  },
  {
    id: MOCK_ID,
    slug: MOCK_ID,
    category: 'CLOTHING',
    name: 'Color Analysis',
    images: [],
    description:
      'What you will do\n' +
      'In our scheduled video call, we will discuss your survey details and talk about the best silhouettes to flatter your body. Next, we will talk about patterns and colors that will make you stand out. Lastly we will talk about the best designs to fit your lifestyle. \n' +
      'With all of this information, I will take you on a virtual shopping tour with preselected brands I have chosen for you. As we look at different pieces, you can tell me what you like and don’t like. At the end of the call, you will have a virtual shopping cart filled with clothing pieces you will love and will complete your wardrobe. You can choose to purchase whatever items you like at your leisure.',
    price: {
      amount: 100,
      currency: 'USD',
    },
    mainImage: `${ENV}/user/session_1.jpeg?height=2299&left=1392&rotate=0&top=158&width=1724&x=-65.30592418968699&y=6.491323556899843&zoom=1.159842728376391`,
    duration: {
      minutes: 60,
    },
    rating: {
      averageScore: 4.78,
      numberOfRatings: 46,
    },
  },
  {
    id: MOCK_ID,
    slug: MOCK_ID,
    category: 'CLOTHING',
    name: 'Capsule Wardrobe',
    images: [],
    description:
      'What you will do\n' +
      'In our scheduled video call, we will discuss your survey details and talk about the best silhouettes to flatter your body. Next, we will talk about patterns and colors that will make you stand out. Lastly we will talk about the best designs to fit your lifestyle. \n' +
      'With all of this information, I will take you on a virtual shopping tour with preselected brands I have chosen for you. As we look at different pieces, you can tell me what you like and don’t like. At the end of the call, you will have a virtual shopping cart filled with clothing pieces you will love and will complete your wardrobe. You can choose to purchase whatever items you like at your leisure.',
    price: {
      amount: 100,
      currency: 'USD',
    },
    mainImage: `${ENV}/user/session_2.jpeg?height=2667&left=505&rotate=0&top=-4&width=2000&x=114&y=1&zoom=1`,
    duration: {
      minutes: 60,
    },
    rating: {
      averageScore: 4.78,
      numberOfRatings: 46,
    },
  },
]

export const MOCK_BOOKING_DATA: FullSessionBooking = {
  id: MOCK_ID,
  sessionId: MOCK_ID,
  isReadyForReview: false,
  isRescheduled: false,
  canEditFeedback: false,
  canAddFeedback: false,
  name: 'Online Shopping Session',
  url: '',
  cancelledBy: '',
  isReschedulable: false,
  hasFeedback: true,
  amount: 50,
  itemised: [],
  currency: 'USD',
  stylistCity: 'Los Angeles',
  stylistCountry: 'USA',
  stylistAbout: '',
  stylistCategory: 'HAIR',
  stylistAvatar: IMAGES_MOCK[0],
  description:
    'What you will do\n' +
    'In our scheduled video call, we will discuss your survey details and talk about the best silhouettes to flatter your body. Next, we will talk about patterns and colors that will make you stand out. Lastly we will talk about the best designs to fit your lifestyle. \n' +
    'With all of this information, I will take you on a virtual shopping tour with preselected brands I have chosen for you. As we look at different pieces, you can tell me what you like and don’t like. At the end of the call, you will have a virtual shopping cart filled with clothing pieces you will love and will complete your wardrobe. You can choose to purchase whatever items you like at your leisure.',
  stylistId: MOCK_ID,
  stylistSlug: MOCK_ID,
  customerId: MOCK_ID,
  stylistName: 'Adriana',
  customerBookingNotes: null,
  customerName: 'Jenny',
  customerCountry: 'Los Angeles',
  customerCity: 'USA',
  images: IMAGES_MOCK,
  whatToBring: [
    'Black Mascara',
    'Your own kind of foundation',
    'Something else',
    'And the last item they need to bring',
  ],
  when: '2021-10-08T15:30:00+00:00',
  duration: 90,
  bookingStatus: 'CONFIRMED',
}

export const MOCK_REVIEW_DATA = [
  {
    date: '2005-12-05',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id mollis mauris. Sed vel tellus magna. In hac habitasse platea dictumst. Morbi feugiat ligula vel vulputate condimentum. Nulla sed hendrerit nisi, sed convallis nulla',
    reviewer: {
      avatar: `${ENV}/user/portfolio_1.jpeg`,
      fullName: 'Daria Owen',
    },
  },
  {
    date: '2005-10-05',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id mollis mauris. Sed vel tellus magna. In hac habitasse platea dictumst.',
    reviewer: {
      avatar: `${ENV}/user/portfolio_2.jpeg`,
      fullName: 'Eren Petrson',
    },
  },

  {
    date: '2005-12-20',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris id mollis mauris. Sed vel tellus magna. In hac habitasse platea dictumst. Morbi feugiat ligula vel vulputate condimentum. Nulla sed hendrerit nisi, sed convallis nulla',
    reviewer: {
      avatar: `${ENV}/user/portfolio_3.jpeg`,
      fullName: 'Michelle Alana',
    },
  },
]
